import React from "react"

const AboutPageArticleImg = ({ profileImage, altNameVal, cssClass }) => {
  return (
    <div className={cssClass}>
      <img src={profileImage} alt={altNameVal} />
    </div>
  )
}

export default AboutPageArticleImg
