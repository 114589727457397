import React, { useState } from "react"
import { Element, scroller } from "react-scroll"

import "../styles/aboutPageStyles.scss"

import aws from "../images/tech/aws.svg"
import graphql from "../images/tech/graphql.svg"
import js from "../images/tech/js.svg"
import nodejs from "../images/tech/node.svg"
import reactjs from "../images/tech/react.svg"
import reactnative from "../images/tech/react-native.svg"
import serverless from "../images/tech/serverless-framework.png"
import typescript from "../images/tech/ts.svg"
import docker from "../images/tech/docker.svg"
import css from "../images/tech/css.png"
import k8s from "../images/tech/kubernetes.svg"
import nextjs from "../images/tech/next-js.svg"

import ash from "../images/ashley.png"

import backendService from "../images/backend-service.svg"
import frontendService from "../images/frontend-service.svg"
import consultancyService from "../images/consultancy-service.svg"
import mentorshipService from "../images/mentorship-service.svg"

import TechStack from "../components/Tech-Stack-Images/tech-stack"
import Contact from "../components/New-Contact-Form/new-contact-form"
import AboutPageArticleText from "../components/About-Page-Article/about-page-article"
import AboutPageArticleImage from "../components/About-Page-Article-Img/about-page-article-img"
import AboutMeService from "../components/About-Me-Service/about-me-service"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"
import H3Title from "../components/H3-Title/h3-title"

import {
  ghostAboutMeParagraph1,
  ghostAboutMeParagraph2,
  ghostAboutMeParagraph3,
  ghostAboutMeParagraph4,
} from "../constants/about-me-text"

import {
  backendChoiceParagraphText,
  backendChoiceTitleText,
  frontEndChoiceParagraphText,
  frontEndChoiceTitleText,
  mentoringChoiceParagraphText,
  mentoringChoiceTitleText,
  businessPropositionsParagraphText,
  businessPropositionsTitleText,
} from "../constants/choice-text"

const images = [
  { imageRef: aws, imageName: "AWS" },
  { imageRef: serverless, imageName: "Serverless" },
  { imageRef: graphql, imageName: "GraphQL" },
  { imageRef: css, imageName: "CSS" },
  { imageRef: js, imageName: "JavaScript" },
  { imageRef: typescript, imageName: "TypeScript" },
  { imageRef: nextjs, imageName: "Next JS" },
  { imageRef: nodejs, imageName: "Node JS" },
  { imageRef: reactjs, imageName: "React JS" },
  { imageRef: reactnative, imageName: "React Native" },
  { imageRef: docker, imageName: "Docker" },
  { imageRef: k8s, imageName: "Kubernetes" },
]

const serviceData = [
  {
    imageRef: backendService,
    altName: "Backend",
    title: backendChoiceTitleText,
    text: backendChoiceParagraphText,
  },
  {
    imageRef: frontendService,
    altName: "Frontend",
    title: frontEndChoiceTitleText,
    text: frontEndChoiceParagraphText,
  },
  {
    imageRef: mentorshipService,
    altName: "Mentorship",
    title: mentoringChoiceTitleText,
    text: mentoringChoiceParagraphText,
  },
  {
    imageRef: consultancyService,
    altName: "Consultancy",
    title: businessPropositionsTitleText,
    text: businessPropositionsParagraphText,
  },
]

const AboutPage = () => {
  const [isServiceSelected, setIsServiceSelected] = useState({
    value: false,
    data: null,
  })

  const handleTheClick = () => {
    scroller.scrollTo("ghost-services-badge-description-popup", {
      smooth: true,
      offset: -60,
    })
  }

  return (
    <>
      <div className="ap-about-me-title">{/* <h1>About Me</h1> */}</div>
      <div className="ghost-about-page-intro">
        <AboutPageArticleImage
          cssClass="ghost-about-page-intro-image-container"
          profileImage={ash}
          altNameVal="Ashley Patricks"
        />
        <AboutPageArticleText cssClass="ghost-about-page-intro-text-container">
          <p>{ghostAboutMeParagraph1}</p>
          <p>{ghostAboutMeParagraph2}</p>
          <p>{ghostAboutMeParagraph3}</p>
          <p>{ghostAboutMeParagraph4}</p>
        </AboutPageArticleText>
      </div>
      <div className="ghost-about-page-tech-stack">
        <h1>Technology Stack</h1>
        {/*<p>Some of the technologies that I currently work with:</p> */}
        <div className="ghost-tech-stack-images">
          {images.map(({ imageName, imageRef }) => {
            return (
              <div className="ghost-tech-stack-bundle">
                <TechStack
                  techImage={imageRef}
                  cssClass="ghost-tech-stack-image-card"
                />
                <p>{imageName}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="ghost-tech-services-badge-container">
        <h1>Services</h1>
        <p>Click on a Service for more information</p>
        <div className="ghost-tech-services-badges">
          {serviceData.map(({ imageRef, altName, title, text }, index) => {
            return (
              <AboutMeService
                imgFile={imageRef}
                altText={altName}
                title={title}
                text={text}
                key={index}
                setServiceSelection={setIsServiceSelected}
                selectionValue={isServiceSelected}
                scrollMe={handleTheClick}
              />
            )
          })}
        </div>
      </div>
      {isServiceSelected.value ? (
        <Element
          name="ghost-services-badge-description-popup"
          className="ghost-services-badge-extra-detail"
        >
          <p>{isServiceSelected.data}</p>
        </Element>
      ) : (
        <>
          <Element
            style={{ visibility: "hidden" }}
            name="ghost-services-badge-description-popup"
          />
          <div className="about-me-spacing-div"></div>
        </>
      )}
      {/* <H3Title titleText="What Other People Are Saying About Me:" /> */}
      <TestimonialBelt />
      <Contact />
    </>
  )
}

export default AboutPage
