import React from "react"

const TechStack = ({ techImage, cssClass }) => {
  return (
    <div className={cssClass}>
      <img src={techImage} className={cssClass} alt="tech" />
    </div>
  )
}

export default TechStack
