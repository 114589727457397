/* BACKEND */
const backendChoiceTitleText = "Backend Development & Cloud Computing"
const backendChoiceParagraphText =
  "I can build, secure, scale and optimise the backend services, databases and APIs which govern the data and business logic features for your applications in the cloud."

/* FRONTEND */
const frontEndChoiceTitleText = "Frontend Development & Hybrid Mobile Apps"
const frontEndChoiceParagraphText =
  "I can create beautiful, responsive user interfaces for websites and mobile screens / apps that your users will interact with on their chosen device."

/* BUSINESS */
const businessPropositionsTitleText =
  "Propositions, Pitching, Presales Consultation"
const businessPropositionsParagraphText =
  "Helping organisations to generate new business and / or funding by positioning, presenting, and pitching their digital products and services from a technical perspective."

/* MENTORING */
const mentoringChoiceTitleText = "Mentoring, Teaching, Training & Coaching"
const mentoringChoiceParagraphText2 =
  "I take individuals from a diverse set of backgrounds, ages and orientations and set them up for success within the software industry by providing them with both the skills and mindset that they need to thrive in their careers or entrepreneurship."
const mentoringChoiceParagraphText =
  "I will set you up for success within the software industry by providing you with both the skills and mindset that you need to thrive in your career or entrepreneurship, whether you are technical or business minded."

export {
  backendChoiceTitleText,
  backendChoiceParagraphText,
  frontEndChoiceTitleText,
  frontEndChoiceParagraphText,
  mentoringChoiceTitleText,
  mentoringChoiceParagraphText,
  businessPropositionsTitleText,
  businessPropositionsParagraphText,
}
