import React from "react"

import "./about-me-service.scss"

const AboutMeService = ({
  imgFile,
  altText,
  title,
  text,
  setServiceSelection,
  scrollMe,
}) => {
  const handleOnClickCallback = () => {
    setServiceSelection({ value: true, data: text })
    scrollMe()
  }

  return (
    <div
      className="ghost-tech-services-badge"
      onClick={handleOnClickCallback}
      onKeyDown={handleOnClickCallback}
      role="button"
      tabIndex={0}
    >
      <p>{title}</p>
      <img src={imgFile} alt={altText} />
    </div>
  )
}

export default AboutMeService
