const ghostAboutMeParagraph1 =
  "A software and technology enthusiast with strong business acumen and more than 10 years of experience working within the Online Food Ordering, Fintech, Education, Mobility and Robotics industries. Curently, I am working within the venture capitalist, funding and entrepreneurship space."

const ghostAboutMeParagraph2 =
  "As a Principle Full Stack Software Engineer and Technical Lead with an eye for the bigger picture, I love to be where the problems are - because solving problems is where true value is found. I specialise in creating high quality, scalable software and digital products for companies ranging from enterprise organisations to startups just beginning their journey."

const ghostAboutMeParagraph3 =
  "I also provide mentorship and coaching for software engineers of all levels whether they are students, professionals or individuals switching professions and just getting started. Along the way, I've also helped startups and entrepreneurs to transform their ideas into reality, and align software with their business goals."

const ghostAboutMeParagraph4 =
  "I'm excited about venturing on new journeys and acquiring more experience, knowledge, tooling, ideas and providing value within my area of influence focusing on the entrepreneurship, leadership and growth of technology within companies and individuals."

export {
  ghostAboutMeParagraph1,
  ghostAboutMeParagraph2,
  ghostAboutMeParagraph3,
  ghostAboutMeParagraph4,
}
